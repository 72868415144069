import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';

import Layout from '@components/Layout';
import Images from '@components/Images';
import EventForm from '@components/EventForm';

import { linkResolver } from '@utils/Prismic/linkResolver';
import { extractGraphqlData } from '@utils/extractors';

import { ATHENS_TABS } from '@utils/constants';

interface IAthensEventProps {
  data: {
    prismic: {
      allAthens_event_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const AthensEvent: React.FC<IAthensEventProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allAthens_event_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  const [currentTab, setCurrentTab] = useState(ATHENS_TABS[0].key);

  return (
    <Layout
      title={page_title ? RichText.asText(page_title) : 'Athens Event'}
      description={page_description && RichText.asText(page_description)}
      firstLevelPage
      seo={{
        path: '/athens-event',
        canonical: canonical_tag && canonical_tag.url,
        image: page_og_image ? page_og_image : null,
      }}
      noNavi={true}
      logoNotClickable={true}
      norobots={true}
    >
      <div className="athens-event">
        {/* Top title */}
        <div className="athens-event__top">
          <div className="athens-event__top-inner">
            <h1>
              <span className="athens-event__heading-text">
                {RichText.asText(doc.node.heading)}
              </span>
              <span className="athens-event__heading-logo">A taste of Athens</span>
            </h1>
          </div>
        </div>

        {/* left block */}
        <div className="athens-event__left">
          <Images
            imageData={[{ image: doc.node.background_image }]}
            extractPath="image"
            alt={_get(doc, ['node', 'background_image', 'alt'])}
            classes={{
              base: '',
              extend: 'athens-event__bg',
              class: '',
            }}
            width="481"
            setHeight={false}
          />
          <div className="athens-event__left-inner">
            <div className="athens-event__contact athens-event__tbox">
              {RichText.render(doc.node.event_contact, linkResolver)}
            </div>
          </div>
        </div>

        {/* main block */}
        <div className="athens-event__content">
          <div className="athens-event__tabs">
            <ul className="athens-event__tabs-nav">
              {ATHENS_TABS.map(tab => (
                <li key={tab.key} className="athens-event__tabs-nav-item">
                  <a
                    href={`#${tab.key}`}
                    className={`athens-event__tabs-nav-link ${
                      tab.key === currentTab ? 'active' : ''
                    }`}
                    onClick={() => setCurrentTab(tab.key)}
                  >
                    {tab.text}
                  </a>
                </li>
              ))}
            </ul>
            <div className={`athens-event__tab ${currentTab !== 'registration' ? 'hidden' : ''}`}>
              <EventForm />
            </div>
            <div className={`athens-event__tab ${currentTab !== 'itinerary' ? 'hidden' : ''}`}>
              {RichText.render(doc.node.itinerary, linkResolver)}
            </div>
          </div>
        </div>

        {/* right block */}
        <div className="athens-event__right">
          <div className="athens-event__right-inner">
            <Images
              imageData={[{ image: doc.node.logo }]}
              extractPath="image"
              alt={_get(doc, ['node', 'logo', 'alt'])}
              classes={{
                base: 'athens-event__logo',
                extend: '',
                class: '',
              }}
              width="161"
              setHeight={false}
            />
            <div className="athens-event__departure athens-event__tbox">
              <div className="athens-event__departure-logo"></div>
              {RichText.render(doc.node.departure, linkResolver)}
            </div>
            <div className="athens-event__return athens-event__tbox">
              <div className="athens-event__return-logo"></div>
              {RichText.render(doc.node.return, linkResolver)}
            </div>
            <div className="athens-event__accomodation athens-event__tbox">
              {RichText.render(doc.node.accomodation, linkResolver)}
            </div>
            <div className="athens-event__google-map">
              <iframe
                src={doc.node.google_maps_iframe_code}
                width="312"
                height="312"
                style={{ border: 0 }}
                allowFullScreen={false}                
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query AthensEvent {
    prismic {
      allAthens_event_pages {
        edges {
          node {
            heading
            itinerary
            event_contact
            background_image
            logo
            departure
            return
            accomodation
            google_maps_iframe_code
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AthensEvent;
